import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/contactButton';
import envelopeImage from '../images/envelope-icon.svg';


const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-center;
  margin-top: 16px;
`;

const InfoRowButtons = styled(InfoRow)`
  @media(max-width: 800px) {
    display: grid;
  }

  @media(max-width: 600px) {
    flex-flow: column;
    align-items: flex-start;
  }

  a, button {
    width: 100%;
  }
`;

const EnvelopeButton = styled.button`
  min-width: 170px;
  padding: 14px 26px;
  min-height: 50px;

  :before {
    margin-right: 7px;
    vertical-align: sub;
    content: url(${envelopeImage});
  }

  @media(max-width: 800px) {
    margin: 16px 0 0 0;
  }
`;

const detailsDriveTips = props => (
  <>
    <InfoRowButtons>
      <ContactButton
        phone={props.phone}
        height='50px'>
        Kontaktieren
      </ContactButton>
      <a href={`mailto:${props.email}`}>
        <EnvelopeButton>
          {props.email}
        </EnvelopeButton>
      </a>
    </InfoRowButtons>
  </>
)

export default detailsDriveTips