import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  width: 100%;
  margin-bottom: 10px;
`;

const DriveTips = styled.p`
  color: #494949;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
  margin: 0;
`

const detailsDriveTips = props => (
  <StyledWrapper>
    <DriveTips>
      {props.tips}
    </DriveTips>
  </StyledWrapper>
)

export default detailsDriveTips