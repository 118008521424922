import React, { Component } from 'react'
import axios from 'axios';
import styled from 'styled-components'

import { URL } from '../utils/utils';
import closeButton from '../images/close.svg';

const StyledWrapper = styled.section`
  width: 100%;
  padding: 28px 38px;   
  background-color: #FCFCFC;
  border-radius: 10px;
`

const UserMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 24px;
  background: #009ba8;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-out;
  transform: ${props => props.open ? "translateY(0)" : "translateY(-100%)"};
  opacity: ${props => props.open ? 1 : 0};

  p {
    font-family: ProximaNova-Regular, sans-serif;
    color: #fff;
    font-size: 15px;
  }
`;

const Button = styled.button`
  padding: 0 0 0 22px;
  display: flex;
  align-self: flex-start;
`;

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.12px;
  margin: 0 0 26px;
`

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 20px;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const ContactTips = styled.p`
color: #494949;
font-family: 'ProximaNova-Regular', sans-serif;
font-size: 15px;
letter-spacing: 0.06px;
margin: 0 0 33px;
`

const Input = styled.input`
  padding: 13px 16px;
  border: 6px solid #ededed;
  color: #494949;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
`

const Textarea = styled(Input)`
  height: 192px;
  grid-column: 1 / 3;
  resize: vertical;

  @media(max-width: 800px) {
    grid-column: auto;
  }
`

class DetailsContactForm extends Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    displayMessage: false
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.target);
    data.append('recipient', this.props.recipient);

    axios.post(`${URL}/api/contact`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(() => {
      this.setState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
        displayMessage: true
      });
    })
    .catch(err => console.log(err));
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  hideMessage = () => {
    this.setState({displayMessage: false});
  }

  render() {
    return (
      <StyledWrapper id="contact">
        <UserMessage open={this.state.displayMessage}>
          <p>Danke für Ihre Nachricht. Wir bemühen uns, diese schnellstmöglich zu beantworten. Ihr Orthopodo Team.</p>
          <Button onClick={this.hideMessage}>
            <img src={closeButton} alt="Close"></img>
          </Button>
        </UserMessage>
        <H3>Kontaktformular</H3>
        <ContactTips>
          Gerne können Sie uns mittels dieses Formulars eine Nachricht zukommen lassen. Ihre Anfrage wird in der Regel innerhalb von 24 Stunden beantwortet.
        </ContactTips>
        <form onSubmit={this.handleSubmit}>
          <Inputs>
            <Input
              type="text"
              name="firstname"
              placeholder="Vorname"
              value={this.state.firstname}
              onChange={event => this.handleChange(event)}
              required />
            <Input
              type="text"
              name="lastname"
              placeholder="Nachname"
              value={this.state.lastname}
              onChange={event => this.handleChange(event)}
              required />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={event => this.handleChange(event)}
              required />
            <Input
              type="tel"
              name="phone"
              placeholder="Telefon"
              value={this.state.phone}
              onChange={event => this.handleChange(event)} />
            <Textarea
              as="textarea"
              name="message"
              placeholder="Ihre Nachricht..."
              value={this.state.message}
              onChange={event => this.handleChange(event)}
              required />
          </Inputs>
          <button>Senden</button>
        </form>
    </StyledWrapper>
    )
  }
}

export default DetailsContactForm;