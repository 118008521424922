import React, { Component } from 'react';
import { navigate } from "gatsby"
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/de';
import GlobalStyles from '../components/globalStyles';
import Header from '../components/header';
import DetailsHeader from '../components/detailsHeader';
import DetailsContact from '../components/detailsContact';
import DetailsGallery from '../components/detailsGallery';
import DetailsHours from '../components/detailsHours';
import DetailsDriveTips from '../components/detailsDriveTips';
import DetailsContactForm from '../components/detailsContactForm';
import LocalFilter from '../components/localFilter'
import mapMark from '../images/map-mark.svg';
import { URL } from '../utils/utils';
import DoctorInfo from '../components/doctorInfo';
import homeIcon from '../images/home-icon-black.svg'
import officeIcon from '../images/office-icon-black.svg'
import upArrowIcon from '../images/up-arrow-green.svg'


const StaticMap = styled.div`
  width: 100%;
  height: 160px;
  margin: 16px 0;
  cursor: default !important;
`;

const ColumnsWrapper = styled.div`
  background-color: #009ba8;
  width: 100%;
  padding: 30px 0;
`

const Columns = styled.div`
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 30px;

  @media (min-width: 1600px) {
    width: 70%;
  }

  @media (max-width: 1280px) {
    
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding: 0px 15px;
  }

  @media (max-width: 480px) {
    width: 96%;
  }
`

const LeftColumn = styled.div`

  @media (max-width: 600px) {
    padding: 0;
  }
`

const RightColumn = styled.div`
  background-color: #FCFCFC;
  border-radius: 10px;
  padding: 30px;

  @media (max-width: 600px) {
    padding: 30px 16px;
  }

  button {
    margin-bottom: 16px;
  }
`

const ContactFormWrapper = styled.div`
  margin: 30px auto 0;
  width: 80%;

  @media (min-width: 1600px) {
    width: 70%;
  }

  @media (max-width: 1280px) {
    display: block;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    width: 96%;
  }
`

const NoDoctorsInfo = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  margin-top: 10px;
`

const FiltersLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 800px) {
    display: block;
  }
`

const FiltersSwitch = styled.div`
  color: #009BA8;
  cursor: pointer;
  user-select: none;

  @media (max-width: 800px) {
    display: none;
  }
  
  img {
    margin-left: 6px;

    &.icon-reversed {
      transform: rotate(180deg)
    }
  }
`

const FiltersSwitchMobile = styled.div`
  color: #009BA8;
  cursor: pointer;
  user-select: none;
  margin-bottom: 16px;
  
  @media (min-width: 801px) {
    display: none;
  }

  img {
    margin-left: 6px;

    &.icon-reversed {
      transform: rotate(180deg)
    }
  }
`

const FiltersList = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

const VisitTypesInfo = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 30px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;

  @media (max-width: 600px) {
    padding: 8px;
    font-size: 14px;
  }

  span {
    color: #494949;
    display: inline-flex;
    align-items: center;
    margin-right: 30px;

    @media (max-width: 600px) {
      margin-right: 16px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    img {
      margin-right: 5px;
      filter: contrast(0) sepia(100%) hue-rotate(116deg) brightness(0.7) saturate(0.28);
    }
  }
`

const H1 = styled.h1`
  margin-bottom: 24px;
`

const H2 = styled.h2`
  font-family: 'GaramondCond-Bold', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  margin-bottom: 24px;
`

const Box = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  background-color: #FCFCFC;
  border-radius: 10px;
  padding: 32px;

  @media (max-width: 600px) {
    padding: 16px;
  }

  &.team-box {
    margin-top: 32px;
  }
`

const FeaturedEmployeesSection = styled.div`
  margin-top: 32px;
`

const StandardEmployeesSection = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 800px) {
    margin-top: 16px;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

const EmployeeBox = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  word-break: break-word;

  @media (max-width: 600px) {
    font-size: 14px;

    &.standard-box {
      width: 100%;
    }
  }

  .left {
    margin-right: 16px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 4px;
    }

    .image-placeholder {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
      background: #ccc;
    }
  }

  .right {

    .name {
      font-weight: bold;
      color: #494949;
    }

    .specialization {
      color: #828282;
      margin-top: 8px;
    }

    .description {
      color: #494949;
      margin-top: 8px;
    }
  }
`

const AdditionalInfo = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #fff;
  padding: 16px;

  @media (max-width: 600px) {
    padding: 12px;
    font-size: 14px;
  }

  .title {
    color: #EB5757;
  } 
  
  .text {
    margin-top: 4px;
  }
`


class Details extends Component {
  state = {
    ready: false,
    locationDetails: null,
    doctors: [],
    isFilterOpen: false,
    categories: [],
    selectedCategories: [],
    updateDoctorsData: false,
  }

  componentDidMount() {
    this.setState({ready: true})
    const place = new URLSearchParams(window.location.search).get('place')
    const categories = new URLSearchParams(window.location.search).get('category')

    if (categories) {
      this.setState({ selectedCategories: categories.split(',').map(item => parseInt(item)) })
    }

    axios.get(`${URL}/api/categories`)
      .then(response => {
        this.setState({ categories: response.data });
      })
      .catch(err => console.log(err));

    axios.get(`${URL}/api/places/${place}`)
      .then(response => this.setState({locationDetails: response.data}))
      .then(() => this.initMap())
      .catch(err => console.log(err))

    let doctorsUrl = `${URL}/api/doctors?place=${place}`
    if (categories) doctorsUrl += `&category=${categories}`

    axios.get(doctorsUrl)
      .then(response => {
        this.setState({ doctors: response.data })
      })
      .catch(err => console.log(err))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.updateDoctorsData && !prevState.updateDoctorsData) {
      const place = new URLSearchParams(window.location.search).get('place')
      let doctorsUrl = `${URL}/api/doctors?place=${place}`
      if (this.state.selectedCategories.length) doctorsUrl += `&category=${this.state.selectedCategories.join(',')}`
      axios.get(doctorsUrl)
        .then(response => {
          this.setState({ 
            doctors: response.data,
            updateDoctorsData: false,
          })
          navigate(`/location-details/?place=${place}&category=${this.state.selectedCategories.join(',')}`)
        })
        .catch(err => console.log(err))
    }
  }

  initMap = () => {
    const myLatLng = { lat: this.state.locationDetails.latitude, lng: this.state.locationDetails.longitude }
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: myLatLng,
      zoom: 12,
      gestureHandling: 'greedy'
    })

    const marker = new window.google.maps.Marker({
      position: myLatLng,
      url: this.state.locationDetails.map,
      map: map,
      icon: mapMark,
    });

    marker.addListener('click', function() {
      window.open(marker.url, '_blank');
    })
  }

  render() {
    let locationDetails;
    let days = {
      MONTAG: { openHours: [], closeHours: []},
      DIENSTAG: { openHours: [], closeHours: []},
      MITTWOCH: { openHours: [], closeHours: []},
      DONNERSTAG: { openHours: [], closeHours: []},
      FREITAG: { openHours: [], closeHours: []},
      SAMSTAG: { openHours: [], closeHours: []},
      SONNTAG: { openHours: [], closeHours: []}
    };

    if (this.state.locationDetails) {
      const { name, pathLogo, city, street, phone, email, periods, pathImage, pictures, direction } = this.state.locationDetails;

      periods.forEach(period => {
        days[period.open_day].openHours.push(moment(period.open_time).format('HH:mm'));
        days[period.open_day].closeHours.push(moment(period.close_time).format('HH:mm'));
      });

      locationDetails = (
        <>
          <DetailsHeader
            locationLogo={pathLogo}
            address={city + ' ' + street}
	    phone={phone}
          />
          <DetailsContact
            phone={phone}
            email={email} />
          <StaticMap id="map" />
          <DetailsDriveTips
            tips={direction} />
          <DetailsHours
            locationName={name}
            periods={days} />
          {
            this.state.locationDetails.additional_info && <AdditionalInfo>
              <div className="title">Achtung!</div>
              <div className="text">{this.state.locationDetails.additional_info}</div>
            </AdditionalInfo>
          }
          <DetailsGallery
            mainImage={pathImage}
            pictures={pictures} />
        </>
      )
    }
        
    return (
      <div style={{
        visibility: this.state.ready ? 'visible' : 'hidden'
      }}>
        <GlobalStyles />
        <Header />
        <ColumnsWrapper>
          <Columns>
            <LeftColumn>
              <Box>
                <H1>{this.state.locationDetails && this.state.locationDetails.name}</H1>
                <H2>Terminbuchung</H2>
                <FiltersLine>
                  <FiltersSwitch onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })}>
                    Anzeige einschränken
                    <img src={upArrowIcon} className={this.state.isFilterOpen && 'icon-reversed'} alt="arrow" />
                  </FiltersSwitch>
                  {
                    this.state.doctors && this.state.doctors.length > 0
                      && <VisitTypesInfo>
                      <span>
                        <img src={officeIcon} alt="office visit" />
                        Praxisbesuch
                      </span>
                      <span>
                        <img src={homeIcon} alt="online visit" />
                        Online Gespräch
                      </span>
                    </VisitTypesInfo>
                  }
                </FiltersLine>
                <FiltersSwitchMobile onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })}>
                  Anzeige einschränken
                  <img src={upArrowIcon} className={this.state.isFilterOpen && 'icon-reversed'} alt="arrow" />
                </FiltersSwitchMobile>
                {
                  this.state.isFilterOpen && <FiltersList>
                    {
                     this.state.categories.map((category, i) => 
                      <LocalFilter
                        key={i}
                        image={category.pathImage}
                        name={category.name}
                        checked={this.state.selectedCategories.indexOf(category.id) !== -1}
                        onChange={() => {
                          const newSelectedCategories = this.state.selectedCategories
                          if (this.state.selectedCategories.indexOf(category.id) === -1) {
                            newSelectedCategories.push(category.id)
                          } else {
                            newSelectedCategories.splice(this.state.selectedCategories.indexOf(category.id), 1)
                          }
                          this.setState({ 
                            selectedCategories: newSelectedCategories,
                            updateDoctorsData: true,
                           })
                        }}
                      />)
                    }
                  </FiltersList>
                }
                {
                  this.state.doctors && this.state.doctors.length
                    ? this.state.doctors.map((doctor, i) => <DoctorInfo key={i} data={doctor} />)
                    : <NoDoctorsInfo>Für diese Kategorie/ Standort sind keine online Buchungen möglich.<br/>Bitte kontaktieren Sie uns telefonisch.</NoDoctorsInfo>
                }
              </Box>
              <Box className="team-box">
                <H2>Team</H2>
                <FeaturedEmployeesSection>
                  {
                    this.state.locationDetails && this.state.locationDetails
                      .employees.filter(item => item.featured)
                      .map((employee, i) => <EmployeeBox key={i}>
                      <div className="left">
                        { employee.pathImage ? <img src={URL + employee.pathImage} alt={employee.name} /> : <div className="image-placeholder" /> }
                      </div>
                      <div className="right">
                        <div className="name">{employee.name}</div>
                        <div className="specialization">{employee.specialization}</div>
                        <div className="description">{employee.description}</div>
                      </div>
                    </EmployeeBox>)
                  }
                </FeaturedEmployeesSection>
                <StandardEmployeesSection>
                  {
                    this.state.locationDetails && this.state.locationDetails.employees
                      .filter(item => !item.featured)
                      .sort((a, b) => a.name < b.name ? -1 : 1)
                      .map((employee, i) => <EmployeeBox className="standard-box" key={i}>
                      <div className="left">
                        { employee.pathImage ? <img src={URL + employee.pathImage} alt={employee.name} /> : <div className="image-placeholder" /> }
                      </div>
                      <div className="right">
                        <div className="name">{employee.name}</div>
                        <div className="specialization">{employee.specialization}</div>
                      </div>
                    </EmployeeBox>)
                  }
                </StandardEmployeesSection>
              </Box>
            </LeftColumn>
            <RightColumn>
              {locationDetails}
            </RightColumn>
          </Columns>
          <ContactFormWrapper>
            <DetailsContactForm recipient={this.state.locationDetails && this.state.locationDetails.email} />
          </ContactFormWrapper>
        </ColumnsWrapper>
      </div>
    )
  }
}

export default Details
