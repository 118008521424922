import React, { Component } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { URL } from '../utils/utils';

const StyledWrapper = styled.section`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`
const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 94px;
  grid-gap: 12px;
  align-items: start;
  justify-items: center;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;

  &:nth-child(1) {
    grid-column: span 3;
    grid-row: span 2;
  }
`

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.12px;
  margin: 0 0 12px 0;
`;


class DetailsGallery extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  };

  openLightbox = () => {
    this.setState({isOpen: true});
  }

  render() {
    let allImages = [`${URL}${this.props.mainImage}`];
    const { photoIndex, isOpen } = this.state;

    this.props.pictures.map(picture => allImages.push(`${URL}${picture.path}`));

    let images = allImages.slice(0, 7).map((picture, index) => (
      <Image
        key={index}
        src={picture}
        onClick={this.openLightbox} />
    ))

    return (
      <StyledWrapper>
        <div>
          <H3>Ansichten</H3>
          <Images>
            {images}
            {isOpen && (
              <Lightbox
                mainSrc={allImages[photoIndex]}
                nextSrc={allImages[(photoIndex + 1) % allImages.length]}
                prevSrc={allImages[(photoIndex + allImages.length - 1) % allImages.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + allImages.length - 1) % allImages.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % allImages.length,
                  })
                }
              />
            )}
          </Images>
        </div>
      </StyledWrapper>
    )
  }
}

export default DetailsGallery;
