import React from 'react'
import styled from 'styled-components'
import { URL } from '../utils/utils';


const HeaderSection = styled.section`
  margin-bottom: 24px;
`;

const Img = styled.img`
  max-width: 200px;

  @media(max-width: 400px) {
    max-width: 100%;
  }
`;

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.05px;
  margin: 0 12px 0 0;
`

const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-center;
  margin-top: 20px;
`;

const Information = styled.p`
  color: #494949;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
  margin: 0;
`

const InformationAddress = styled(Information)`
  display: flex;
  align-items: center;
  align-items: flex-end;
  position: relative;
  bottom: 1px;
`;


const detailsHeader = props => (
  <HeaderSection>
    {props.locationLogo ? <Img src={`${URL}${props.locationLogo}`} alt="location logo" /> : null}
    <InfoRow>
      <H3>Adresse: </H3>
      <InformationAddress>{props.address}</InformationAddress>
    </InfoRow>
    <InfoRow>
      <H3>Telefon: </H3>
      <InformationAddress>{props.phone}</InformationAddress>
    </InfoRow>
  </HeaderSection>
)

export default detailsHeader
