import React from 'react';
import styled from 'styled-components';

import { URL } from '../utils/utils';
import checkboxMark from '../images/checkbox-mark.svg'

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: #494949;
`

const Checkmark = styled.span`
  position: absolute;
  top: 6px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgba(73, 73, 73, 0.5);
  border-radius: 4px;
  cursor: pointer;
`

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ ${Checkmark} {
    background-color: #279b9c;

    :before {
      position: absolute;
      top: 5px;
      left: 4px;
      content: '';
      background-image: url(${checkboxMark});
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }
`

const Img = styled.img`
  margin: 0 5px 0 25px;
`;

const localFilter = props => (
  <Label>
    <Checkbox
      type="checkbox"
      checked={props.checked === undefined ? true : props.checked}
      onChange={props.onChange}
      name={props.name}
    />
    <Checkmark />
    <Img src={`${URL}${props.image}`} alt={props.name} />
    {props.name}
  </Label>
)

export default localFilter;