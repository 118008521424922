import React, { Component } from 'react'
import styled from 'styled-components'
import 'react-image-lightbox/style.css';

const StyledWrapper = styled.section`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`
const About = styled.div`
  display: flex;
  flex-flow: column;
`

const H3 = styled.h3`
  color: #009ba8;
  font-family: 'GaramondCond-Bold', sans-serif;
  font-size: 20px;
  letter-spacing: 0.12px;
  margin: 0 0 12px 0;
`;

const InfoRow = styled.div`
  display: flex;
`;

const ContactInfo = styled.p`
  color: #494949;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.06px;
  margin: 7px 0;
`

const HoursInfo = styled.ul`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const OpenHours = styled.p`
  width: 40px;
  display: inline-block;
`;

const HoursInfoRow = styled(ContactInfo)`
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
  width: 100%;

  p {
    margin: 0;
    text-transform: lowercase;

    :first-letter {
      text-transform: uppercase;
    }
  }

  & > p {
    font-weight: 600;
  }

  @media(max-width: 400px) {
    gap: 40px;
  }
`;

class DetailsHours extends Component {
  render() {
    let OpenHoursInWeek = Object.entries(this.props.periods).map((day) => {
      let openHours;
      if (day[1].openHours.length) {
        openHours = day[1].openHours.map((hours, index) => (
          <div key={index}>
            {<OpenHours>{hours}</OpenHours>}{` - ${day[1].closeHours[index]}`}
          </div>
        ))
      } else {
        openHours = 'Geschlossen';
      }
      return (
        <HoursInfoRow key={day} as="li">
          <p>{day[0]}</p>
          <div>{openHours}</div>
        </HoursInfoRow>
      )
    });

    return (
      <StyledWrapper>
        <About>
          <H3>Sprechzeiten</H3>
          <InfoRow>
            <HoursInfo>
              {OpenHoursInWeek}
            </HoursInfo>
          </InfoRow>
        </About>
      </StyledWrapper>
    )
  }
}

export default DetailsHours
