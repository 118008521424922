import React, { Component } from 'react'
import styled from 'styled-components'
import arrowIcon from '../images/arrow.svg'
import moment from 'moment'
import { Link } from 'gatsby'
import { URL } from '../utils/utils'
import homeIcon from '../images/home-icon-black.svg'
import officeIcon from '../images/office-icon-black.svg'


const DoctorBox = styled.div`
  font-family: 'ProximaNova-Regular', sans-serif;
  background: #FFFFFF;
  color: #494949;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 30px;

  @media(max-width: 960px) {
    display: block;
    padding: 16px 10px;
  }
`

const LeftArea = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding-right: 16px;
  
  @media(max-width: 960px) {
    border-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  @media(max-width: 600px) {
    font-size: 15px;
  }
  
  .first-line {
    display: flex;
    margin-bottom: 16px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-right: 16px;
      border-radius: 4px;
    }

    .name {
      margin-bottom: 4px;
      font-weight: 600;
    }
  }
`

const RightArea = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr 1fr 1fr 28px;
  gap: 16px;

  @media(max-width: 600px) {
    grid-template-columns: 20px 1fr 1fr 1fr 20px;
    gap: 8px;
  }
`

const ArrowLeft = styled.div`
  margin-top: 8px;
  background: #4499A6;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 600px) {
    width: 20px;
    height: 20px;
  }

  img {
    transform: rotate(180deg);
    position: relative;
    left: -1px;

    @media(max-width: 600px) {
      left: -2px;
    }
  }
`

const ArrowRight = styled.div`
  margin-top: 8px;
  background: #4499A6;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 600px) {
    width: 20px;
    height: 20px;
  }
  
  img {
    position: relative;
    left: 1px;

    @media(max-width: 600px) {
      left: 2px;
    }
  }
`

const DayColumn = styled.div`
  min-width: 80px;
  min-height: 200px;
  font-size: 14px;

  @media(max-width: 600px) {
    min-width: 0;
    font-size: 13px;
  }

  .weekday {
    font-weight: 600;
    text-align: center;
  }

  .date {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 10px;    
    color: #999;
  }
`

const Slot = styled.div`
  background: #4499A6;
  border-radius: 2px;
  margin-bottom: 10px;
  height: 28px;

  a {
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img {
      margin-left: 5px;
      height: 16px;
      filter: contrast(0) sepia(100%) hue-rotate(116deg) brightness(10) saturate(0.28);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

class DoctorInfo extends Component {

  state = {
    date: moment()
  }

  render() {
    const { data } = this.props
    const { date } = this.state
    
    const day1 = date
    const day2 = moment(date).add(1, 'day')
    const day3 = moment(date).add(2, 'day')

    const changeCalendarDate = daysAmount => {
      this.setState({
        date: moment(date).add(daysAmount, 'day')
      })
    }

    const renderSlots = date => {
      const allSlots = data.visits
        .filter(slot => moment(slot.start_date).format('YYYY-MM-DD-HH-mm') > moment().format('YYYY-MM-DD-HH-mm'))
      const thisDateSlots = allSlots
        .filter(slot => slot.start_date.split('T')[0] === date.format('YYYY-MM-DD'))
        .filter(slot => !slot.reservation)
        .sort((a, b) => a.start_date > b.start_date ? 1 : -1)

      return <>{
        thisDateSlots.map((slot, i) => <Slot key={i}>
          <Link to={`/booking/?id=${slot.id}`}>
            {moment(slot.start_date).format('HH:mm')}
            <img src={slot.online ? homeIcon : officeIcon} alt="visit type" />
          </Link>
        </Slot>)
      }</>
    }

    return (
      <DoctorBox>
        <LeftArea>
          <div className="first-line">
            <img src={URL + data.pathImage} alt={data.name} />
            <div>
              <div className="name">{data.name}</div>
              <div className="category">{data.category.name}</div>
            </div>
          </div>
          <div>{data.description}</div>
        </LeftArea>
        <RightArea>
          { moment(day1).format('YYYY.MM.DD') > moment().format('YYYY.MM.DD') 
            ? <ArrowLeft onClick={() => changeCalendarDate(-1)}>
              <img src={arrowIcon} alt="arrow left" />
            </ArrowLeft>
            : <div/> 
          }
          <DayColumn>
            <div className="weekday">{moment(day1).format('YYYY.MM.DD') === moment().format('YYYY.MM.DD') ? 'Heute' : day1.format('dddd')}</div>
            <div className="date">{day1.format('DD.MM')}</div>
            {renderSlots(day1)}
          </DayColumn>
          <DayColumn>
            <div className="weekday">{moment(day2).format('YYYY.MM.DD') === moment().add(1, 'day').format('YYYY.MM.DD') ? 'Morgen' : day2.format('dddd')}</div>
            <div className="date">{day2.format('DD.MM')}</div>
            {renderSlots(day2)}
          </DayColumn>
          <DayColumn>
            <div className="weekday">{day3.format('dddd')}</div>
            <div className="date">{day3.format('DD.MM')}</div>
            {renderSlots(day3)}
          </DayColumn>
          <ArrowRight onClick={() => changeCalendarDate(1)}>
            <img src={arrowIcon} alt="arrow right" />
          </ArrowRight>
        </RightArea>
      </DoctorBox>
    )
  }
}

export default DoctorInfo
