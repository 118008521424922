import React, { Component } from 'react'
import styled from 'styled-components';

import phoneImage from '../images/phone-icon.svg'

const PhoneButtonDesktop = styled.button`
  && {
    padding: 11px 2px;
  }
  display: none;
  height: ${props => props.height ? props.height : '40px'};
  width: ${props => props.width ? props.width : 'initial'};
  position: relative;
  font-size: 15px;

  @media(min-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MobileButtonWrapper = styled.div`
  display: none;
  height: 40px;
  width: ${props => props.width ? props.width : '100%'};

  a { width: 100%; }

  @media(max-width: 799px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PhoneButtonMobile = styled.button`
  height: ${props => props.height ? props.height : '40px'};
  width: ${props => props.width ? props.width : '100%'};
  display: flex;
  justify-content: center;
  position: relative;
`;

const Img = styled.img`
  margin-right: 12px;
`;

class ContactButton extends Component {
  state = {
    showNumber: false
  };

  showNumber = () => {
    this.setState({showNumber: true});
  }

  render() {
    return (
      <>
        <PhoneButtonDesktop
          onClick={this.showNumber}
          width={this.props.width}
          height={this.props.height}
          padding={this.props.padding}>
          <Img src={phoneImage} />
          {this.state.showNumber ? this.props.phone : this.props.children}
        </PhoneButtonDesktop>
        <MobileButtonWrapper
          width={this.props.width}>
          <a href={`tel:${this.props.phone.replace(/\s+/g, '-')}`}>
            <PhoneButtonMobile
              width={this.props.width}
              height={this.props.height}
              padding={this.props.padding}>
              <Img src={phoneImage} />
              {this.props.children}
            </PhoneButtonMobile>
          </a>
        </MobileButtonWrapper>
      </>
    );
  }
}

export default ContactButton;